import { tv, type VariantProps } from "../../../util/tv";

export const sideMenuVariant = tv({
  slots: {
    base: [
      "w-full",
      "fixed lg:relative bottom-0 left-0 lg:bottom-auto",
      "px-8 lg:px-0",
      "lg:ml-auto",
      "bg-black/70 lg:bg-transparent ",
      "backdrop-blur lg:backdrop-blur-none",
    ],
    button: ["fixed bottom-8 right-8 mx-auto", "shadow-md", "lg:hidden"],
    chevron: ["w-4 h-4", "duration-300 transform"],
    wrapper: [
      "flex flex-col",
      "h-0 lg:h-auto",
      "mt-3 lg:mt-0",
      "transition-opacity duration-300",
      "lg:opacity-100",
    ],
    menuWrapper: ["h-full", "overflow-y-auto lg:overflow-hidden"],
    menu: ["pb-8 md:pb-0"],
    group: ["relative", "py-[6px] first:pt-3"],
    title: ["text-display-16 font-medium", "py-2"],
    content: ["relative", "mt-[6px]"],
    highlight: ["absolute top-0 right-0", "w-[1px]", "will-change-transform"],
    line: ["absolute inset-y-0 left-0", "w-px"],
    list: ["border-l border-transparent"],
    item: ["relative", "py-[6px] first:pt-0"],
    link: [
      "flex justify-between gap-2",
      "text-display-14 font-medium",
      "px-4",
      "transition",
    ],
  },
  variants: {
    theme: {
      dark: {
        base: [],
        title: ["text-gray-40"],
        highlight: ["bg-white"],
        line: ["bg-gray-500"],
      },
      light: {
        base: [],
        title: ["text-black"],
        highlight: ["bg-black"],
        line: ["bg-black/20"],
      },
    },
    isOpen: {
      false: {
        button: ["z-50"],
        wrapper: ["opacity-0"],
      },
      true: {
        base: ["h-full", "z-[3999]"],
        wrapper: ["h-full", "opacity-100"],
        button: ["z-[4000]"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type SideMenuVariantProps = VariantProps<typeof sideMenuVariant>;

export type SideMenuReturnVariantProps = ReturnType<typeof sideMenuVariant>;
