import { tv, type VariantProps } from "../../../util/tv";

export const layoutGridVariant = tv({
  slots: {
    base: [],
    sidebar: [],
    content: [],
  },
  variants: {
    showSidebar: {
      false: {},
      true: {
        base: ["md:container-fluid", "grid grid-cols-12 gap-0", "pt-28"],
        sidebar: [
          "col-span-12 lg:col-start-1 lg:col-end-3 lg:w-[280px]",
          "lg:border-r lg:border-white/15",
        ],
        content: ["col-span-12 lg:col-span-9 lg:col-start-4", "with-sidebar"],
      },
    },
  },
  defaultVariants: {
    showSidebar: false,
  },
});

export type LayoutGridVariantProps = VariantProps<typeof layoutGridVariant>;
