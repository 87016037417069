"use client";

import { SideMenu } from "../../common/side-menu";
import { SectionProvider } from "./section-context";
import { useLayoutGrid, type UseLayoutGridProps } from "./use-layout-grid";

export type LayoutGridProps = UseLayoutGridProps;

function LayoutGrid(props: LayoutGridProps) {
  const { styles, showSidebar, sections, menu, children } =
    useLayoutGrid(props);

  return (
    <SectionProvider sections={sections}>
      <div className={styles.base()}>
        {showSidebar && !!menu && menu.length > 0 ? (
          <div className={styles.sidebar()}>
            <SideMenu data={menu} />
          </div>
        ) : null}
        <div className={styles.content()}>{children}</div>
      </div>
    </SectionProvider>
  );
}

export default LayoutGrid;
