import { useMemo, useRef } from "react";
import type { GlobalType, Locale } from "../../../types";
import useDisableScroll from "../../../hooks/use-disable-scroll";
import useHeaderState from "./use-header-state";
import { headerVariant } from "./header.variants";

export interface UseHeaderProps {
  locale: Locale;
  siteName: GlobalType["siteName"];
  menu?: GlobalType["menu"];
  cigarbar: GlobalType["cigarbar"];
}

export const useHeader = (props: UseHeaderProps) => {
  const { siteName, menu = [], cigarbar, locale } = props;

  const ref = useRef(null);
  const headerState = useHeaderState();

  const { theme, opened, blur } = headerState;

  const styles = useMemo(
    () => headerVariant({ theme, opened, blur }),
    [theme, opened, blur]
  );

  useDisableScroll(opened, ref);

  return {
    ref,
    styles,
    headerState,
    data: {
      siteName,
      locale,
      menu,
      cigarbar,
    },
  };
};
