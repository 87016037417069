import { tv, type VariantProps } from "../../../util/tv";

export const headerVariant = tv({
  slots: {
    cigarbar: ["fixed top-0 z-[2001]", "w-full"],
    base: [
      "fixed top-[var(--cigar-bar-height)] left-0 z-[2000]",
      "w-full",
      "bg-transparent",
    ],
    bar: [
      "container-fluid",
      "h-20",
      "flex items-center justify-between lg:gap-8",
    ],
    brandLink: ["flex items-center", "focus:outline-none"],
    brand: ["h-7"],
    nav: [
      "flex flex-row items-center justify-between gap-8",
      "w-full",
      "mobile:flex-col mobile:items-start mobile:gap-10",
      "mobile:absolute mobile:z-50 mobile:left-0 mobile:top-[calc(100%_+_160px)]",
      "mobile:pt-2 mobile:pb-10",
      "mobile:transition-all mobile:duration-300",
      "mobile:h-[calc(100vh_-_80px_-_var(--cigar-bar-height))]",
      "mobile:h-[calc(100dvh_-_80px_-_var(--cigar-bar-height))]",
    ],
    group: [
      "desktop:first-of-type:mx-auto",
      "mobile:w-full",
      "mobile:relative",
      "mobile:first-of-type:overflow-y-auto",
      "mobile:first-of-type:ltr:pr-6",
      "mobile:first-of-type:rtl:pl-6",
      "mobile:first-of-type:pb-14",
      'mobile:last-of-type:before:content[""]',
      "mobile:last-of-type:before:absolute",
      "mobile:last-of-type:before:h-16",
      "mobile:last-of-type:before:z-50",
      "mobile:last-of-type:before:bg-gradient-to-t",
      "mobile:last-of-type:before:pointer-events-none",
      "mobile:last-of-type:before:w-[calc(100%-10px)]",
      "mobile:last-of-type:before:bottom-[calc(100%+2.5rem)]",
      "mobile:px-8",
    ],
    list: ["flex items-center gap-3", "mobile:flex-col mobile:items-start"],
    item: ["group", "desktop:w-auto"],
    link: ["mobile:w-full"],
    toggle: ["lg:hidden"],
  },
  variants: {
    theme: {
      dark: {
        brand: ["text-white"],
        nav: ["mobile:bg-black"],
        group: ["mobile:last-of-type:before:from-black", "tiny-scrollbar-dark"],
      },
      light: {
        brand: ["text-black"],
        nav: ["mobile:bg-white"],
        group: [
          "mobile:last-of-type:before:from-white",
          "tiny-scrollbar-light",
        ],
      },
    },
    opened: {
      false: {
        nav: ["mobile:translate-y-full"],
      },
      true: {
        base: [],
        nav: ["mobile:-translate-y-[160px]"],
      },
    },
    blur: {
      true: {},
    },
  },
  compoundVariants: [
    {
      blur: true,
      opened: false,
      className: {
        base: ["backdrop-blur-xl", "border-b"],
      },
    },
    {
      theme: "dark",
      blur: true,
      opened: false,
      className: {
        base: ["bg-header-dark", "border-b-gray-500"],
      },
    },
    {
      theme: "light",
      blur: true,
      opened: false,
      className: {
        base: ["bg-header-light", "border-b-gray-10"],
      },
    },
    {
      theme: "dark",
      opened: true,
      className: {
        base: ["!bg-black"],
      },
    },
    {
      theme: "light",
      opened: true,
      className: {
        base: ["!bg-white"],
      },
    },
  ],
  defaultVariants: {
    theme: "dark",
    opened: false,
    blur: false,
  },
});

export const headerDropdownVariant = tv({
  slots: {
    base: [
      "flex-auto",
      "desktop:absolute desktop:z-10",
      "desktop:w-screen",
      "mobile:!flex",
      "mobile:!opacity-100",
      "mobile:!transform-none",
      "mobile:mt-4",
      "desktop:rounded-3xl",
    ],
    card: [
      "desktop:shadow-sm",
      "desktop:rounded-3xl",
      "desktop:overflow-hidden",
    ],
    list: ["desktop:p-4"],
    item: ["group/item", "relative", "transition-colors duration-300"],
    link: ["font-medium"],
    description: [
      "mt-0.5",
      "font-medium mobile:text-display-16 text-display-14 leading-snug",
      "mobile:hidden",
    ],
    iconWrapper: [
      "mobile:hidden flex flex-none items-center justify-center",
      "h-12 w-12",
      "rounded-lg",
      "mt-1",
    ],
    icon: ["h-6 w-6", "text-blue-100"],
    cta: ["hidden", "desktop:flex justify-between gap-4", "p-8"],
  },
  variants: {
    theme: {
      dark: {
        card: ["desktop:bg-gray-700"],
        link: ["text-white"],
        description: ["text-gray-40"],
        item: ["desktop:hover:bg-gray-400"],
        cta: ["bg-gray-400"],
        iconWrapper: ["bg-white"],
      },
      light: {
        card: ["desktop:bg-gray-5 "],
        link: ["text-black"],
        description: ["text-gray-70"],
        item: ["desktop:hover:bg-white"],
        cta: ["bg-gray-10"],
        iconWrapper: ["bg-white desktop:group-hover/item:bg-gray-1/50"],
      },
    },
    hasCta: {
      false: {
        base: ["desktop:w-[240px]"],
        list: [],
        item: ["rounded-lg"],
        link: [
          "flex",
          "mobile:py-2 desktop:py-3 desktop:px-4",
          "text-display-16",
          "my-auto",
        ],
      },
      true: {
        base: [
          "desktop:max-w-7xl",
          "desktop:px-8",
          "desktop:inset-x-0",
          "desktop:mx-auto",
        ],
        list: [
          "grid grid-cols-1 gap-x-6 gap-y-6 desktop:gap-y-1 lg:grid-cols-3",
        ],
        item: ["flex gap-x-6", "desktop:px-4 desktop:py-3", "rounded-2xl"],
        link: ["mobile:text-display-16 desktop:text-display-18"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
    hasCta: false,
  },
});

export const headerShareBarVariant = tv({
  slots: {
    base: ["fixed z-40", "w-full", "bg-black"],
    content: ["flex justify-center", "w-full", "p-4"],
    progress: ["relative", "h-1 w-full", "bg-gray-100"],
    currentProgress: ["w-100 h-1", "absolute inset-0 z-10", "bg-gray-60"],
  },
});

export type HeaderVariantProps = VariantProps<typeof headerVariant>;

export type HeaderReturnVariantProps = ReturnType<typeof headerVariant>;
