import { useEffect } from "react";
import { useMeasure } from "react-use";
import type { GlobalType } from "../../../types";
import { isBrowser } from "../../../util/env";
import {
  cigarBarVariant,
  type CigarBarVariantProps,
} from "./cigar-bar.variants";

export type UseCigarBarProps = CigarBarVariantProps & {
  className?: string;
  data: GlobalType["cigarbar"];
};

export const useCigarBar = (props: UseCigarBarProps) => {
  const { className = "", data } = props;
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    if (height === undefined || !isBrowser) return;

    document.body.style.setProperty("--cigar-bar-height", `${height}px`);

    return () => {
      document.body.style.removeProperty("--cigar-bar-height");
    };
  }, [height]);

  const styles = cigarBarVariant();

  return {
    ref,
    className,
    styles,
    data,
  };
};
