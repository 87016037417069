"use client";

import NextImage from "next/image";
import { Text } from "../../ui/text";
import { Button } from "../../ui/button";
import { useCigarBar, UseCigarBarProps } from "./use-cigar-bar";

export type CigarBarProps = UseCigarBarProps;

const CigarBar = (props: CigarBarProps) => {
  const { ref, className, styles, data } = useCigarBar(props);

  return (
    <div ref={ref} className={styles.base({ className })}>
      {!!data && (
        <div className={styles.wrapper()}>
          <div className={styles.container()}>
            {!!data?.image && (
              <NextImage
                className={styles.image()}
                src={data?.image.url}
                width={data?.image.width}
                height={data?.image.height}
                alt={data?.title}
              />
            )}
            {!!data?.title && (
              <div className={styles.textWrapper()}>
                <Text className={styles.text()} weight="medium" size="cigarbar">
                  {data.title}
                </Text>
              </div>
            )}
            {!!data?.cta && (
              <Button
                tabIndex={0}
                color="black"
                appearance="link"
                className={styles.action()}
                sectionName="cigarbar"
                size="sm"
                weight="semibold"
                href={data.cta.url}
                title={data.cta.text}
                endIcon="arrow-right"
              >
                {data.cta.text}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CigarBar;
