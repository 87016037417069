/* eslint-disable react-hooks/exhaustive-deps -- disable */
import { useEffect, useRef, useState } from "react";
import { usePathname } from "next/navigation";
import { useEffectOnce, useWindowSize } from "react-use";
import type { ComponentProps, LayoutSideMenuGroup } from "../../../types";
import useDisableScroll from "../../../hooks/use-disable-scroll";
import {
  sideMenuVariant,
  type SideMenuVariantProps,
} from "./side-menu.variants";
import useFilterData from "../../../hooks/use-filter-data";

export type UseSideMenuProps = ComponentProps<
  LayoutSideMenuGroup[],
  SideMenuVariantProps
>;

const MIN_WIDTH_FOR_DESKTOP_SIDE_MENU = 1024;

const debounce = (callback: any, wait: number = 1300) => {
  let timeoutId: number;

  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => callback(...args), wait);
  };
};

export const useSideMenu = (props: UseSideMenuProps) => {
  const { className = "", theme = "dark", data } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState(data);
  const ref = useRef(null);
  const pathname = usePathname();
  const { width } = useWindowSize();

  const styles = sideMenuVariant({ theme, isOpen });

  useDisableScroll(isOpen, ref);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    isOpen && setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    const isDesktopAndOpenedSideMenu =
      width >= MIN_WIDTH_FOR_DESKTOP_SIDE_MENU && isOpen;
    isDesktopAndOpenedSideMenu && setIsOpen(false);
  }, [isOpen, width]);

  useEffect(() => {
    const filterData = () => {
      if (!searchTerm) return;

      const filtered: any = [];

      data.forEach((group) => {
        const filteredGroup = group.menu.filter((menuItem) =>
          menuItem.title
            .toLocaleLowerCase()
            .includes(searchTerm?.toLocaleLowerCase())
        );

        if (filteredGroup.length > 0) {
          filtered.push({ ...group, menu: filteredGroup });
        }
      });

      if (filtered.length > 0) {
        setFilteredData(filtered);
      }
    };

    filterData();
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    debounce(setSearchTerm(value));
    if (value === "") setFilteredData(data);
  };

  return {
    ref,
    theme,
    className,
    styles,
    isOpen,
    setIsOpen,
    data: filteredData,
    searchTerm,
    handleSearchChange,
  };
};
