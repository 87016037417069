"use client";

import { domMax, LazyMotion } from "motion/react";
import { Vector } from "../../ui/vector";
import { Button } from "../../ui/button";
import SideMenuGroup from "./side-menu-group";
import { useSideMenu, type UseSideMenuProps } from "./use-side-menu";
import Input from "../../ui/input/input";

export type SideMenuProps = UseSideMenuProps;

function SideMenu(props: SideMenuProps) {
  const {
    ref,
    className,
    styles,
    theme,
    isOpen,
    setIsOpen,
    data,
    searchTerm,
    handleSearchChange,
  } = useSideMenu(props);

  return (
    <LazyMotion features={domMax}>
      <Button
        appearance="solid"
        className={styles.button()}
        color="gray"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        size="sm"
        type="button"
      >
        <Vector
          className={styles.chevron({
            className: isOpen ? "rotate-180" : "",
          })}
          name="chevron-up"
        />
        <span>Navigation</span>
      </Button>
      <nav
        className={styles.base({ className })}
        data-component-name="side-menu"
        ref={ref}
      >
        <div className={styles.wrapper()}>
          <div className={styles.menuWrapper()}>
            <Input
              type="search"
              placeholder="Search"
              theme={theme}
              appearance="search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <ul className={styles.menu()}>
              {data.map((group, groupIndex) => (
                <SideMenuGroup
                  className={groupIndex === 0 ? "md:mt-0 py-0" : ""}
                  group={group}
                  key={group.title}
                  styles={styles}
                  theme={theme}
                />
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </LazyMotion>
  );
}

export default SideMenu;
