import { useEffect, useMemo, useState } from "react";
import { useParams } from "next/navigation";
import type { LayoutSideMenuGroup, LayoutSideMenuItem } from "../../../types";
import { layoutGridVariant } from "./layout-grid.variants";

export interface UseLayoutGridProps {
  data: {
    docsMenu?: LayoutSideMenuGroup[];
    workflowsMenu?: LayoutSideMenuGroup[];
    modulesMenu?: LayoutSideMenuGroup[];
  };
  children?: React.ReactNode;
}

export const useLayoutGrid = (props: UseLayoutGridProps) => {
  const { children, data } = props;
  const { docsMenu = [], workflowsMenu = [], modulesMenu = [] } = data;
  const [menu, setMenu] = useState<LayoutSideMenuGroup[]>([]);
  const [sections, setSections] = useState<LayoutSideMenuItem[]>([]);

  const params = useParams();
  const [page, category] = Array.isArray(params.slug) ? params.slug : [];

  const showSidebar = ["docs", "workflows", "modules"].includes(page ?? "");

  const styles = layoutGridVariant({ showSidebar });

  const currentMenu = useMemo(() => {
    const menuMap = {
      docs: docsMenu,
      workflows: workflowsMenu,
      modules: modulesMenu,
    };

    return menuMap[page as keyof typeof menuMap];
  }, [page]);

  useEffect(() => {
    const currentSections =
      !!menu && menu.find((group) => group.slug === category)?.menu;
    setMenu(currentMenu);

    if (currentSections) {
      setSections(currentSections);
    }
  }, [currentMenu]);

  return {
    styles,
    showSidebar,
    sections,
    menu,
    children,
  };
};
